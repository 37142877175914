import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    translate: sessionStorage.getItem('translate') || 'zh'
  },
  mutations: {
    changeTranslate(state, value) {
      state.translate = value
    }
  },
  getters: {
    translate: state => {
      return state.translate
    }
  },
  actions: {
  }
})
