<template>
  <div class="footer-wrapper">
    <div class="main">
      <div class="footer-logo">
        <img src="@/assets/image/footer/logo.png" />
      </div>
      <template
        v-for="(item, index) in configLink"
      >
        <div
          :key="index"
          class="main-content-wrapper"
        >
        <template
          v-if="item.list && item.list.length"
        >
          <div
            @click="toLink(item.link)"
            class="list-title"
          >
            {{ $t(item.prop) }}
          </div>
          <div
            class="list-title-list"
          >
            <div
              v-for="items in item.list"
              :key="items.prop"
              @click="toLink(items.link)"
            >
              {{ $t(items.prop) }}
            </div>
          </div>
        </template>
        </div>
      </template>
      <div class="follow">
        <div class="list-title">{{ $t('wechatCode') }}</div>
        <img src="@/assets/image/footer/code.png" />
      </div>
       <div class="follow">
        <a href="https://www.linkedin.com/company/ewinlight/" target="_bank" class="list-title">{{ $t('领英') }}</a>
      </div>
    </div>
    <div class="put-on-record">
      <a href="https://beian.miit.gov.cn/" target="_blank">Copyright-Ewinlight 2019  |  粤ICP备15016492号-1 </a>
      <a href="/privacy-policy" > 隐私政策</a>
    </div>
    <div class="back-top">
      <el-backtop
        :right="100"
        :bottom="100"
      >
        <div class="back-top-img">
          <img src="@/assets/image/toTop.png" />
        </div>
      </el-backtop>
    </div>
  </div>
</template>

<script>
import {configLink} from '@/utils/config'
export default {
  data() {
    return {
      configLink
    }
  },
  methods: {
    toLink(link) {
      this.$router.push(link)
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-wrapper {
  width: 100%;
  height: 520px;
  background: rgba(39, 39, 39, 1);
  font-size: 14px;
  color: #fff;
  .main {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    height: calc(100% - 60px);
    justify-content: space-between;
    .footer-logo {
      display: flex;
      align-items: center;
      height: 100%;
    }
    .list-title {
      font-size: 17px;
      font-weight: bold;
      margin-bottom: 32px;
      cursor: pointer;
    }
    .main-content-wrapper {
      margin-top: 76px;
      .list-title-list {
        font-size: 14px;
        line-height: 32px;
        & > div {
          margin-bottom: 12px;
          cursor: pointer;
        }
      }
    }
    .follow {
      margin-top: 76px;
      a {
        color: #fff;
      }
    }
  }
  .put-on-record {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    a {
      color: rgba(34, 34, 34, 1);
      font-size: 14px;
      text-decoration: none;
      &:nth-child(2) {
        margin-left: 20px;
        display: inline-block;
      }
    }
  }
  .back-top {
    position: absolute;
    right: 40px;
    bottom: 440px;
    z-index: 10;
    cursor: pointer;
    .el-backtop {
      background: none;
      box-shadow: none;
      position: relative;
    }
  }
}
</style>