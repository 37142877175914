<template>
  <div id="app">
    <header-view />
    <keep-alive :exclude="['Article', 'ProductDetails']">
      <router-view />
    </keep-alive>
    <footer-view />
  </div>
</template>

<script>
import HeaderView from '@/components/header/index.vue'
import FooterView from '@/components/footer/index.vue'
export default {
  name: 'app',
  components: {
    HeaderView,
    FooterView
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: 'myFirstFont';
  src: url('~@/assets/font/ahuang.ttf')
}
@font-face {
  font-family: 'mySecondFont';
  src: url('~@/assets/font/ahuang2.ttf')
}
#app {
  font-family: 'PingFangSC-Regular', 'helvetica neue', tahoma, 'PingFang SC', 'microsoft yahei', arial, 'hiragino sans gb', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}
</style>
