<template>
  <div
    class="components-header-wrapper"
  >
  <div
    class="header-mian-wrapper scroll-fixed"
  >
    <div class="header-mian">
      <div class="header-content">
        <div class="header-content-logo">
          <img src="@/assets/image/logo-color.png" >
        </div>
        <div class="header-content-list">
          <template
            v-for="item in headerList"
          >
            <router-link
              :key="item.prop"
              :to="item.link"
              class="header-content-list-tabs"
              tag="div"
              @click.stop
            >
              {{ $t(item.prop) }}
              <div
                v-if="item.list"
                class="header-content-list-tabs-div"
              >
                <div class="header-content-list-tabs-aside">
                  <router-link
                    v-for="items in item.list"
                    :key="items.prop"
                    :to="items.link"
                    tag="div"
                  >
                    {{ $t(items.prop) }}
                  </router-link>
                </div>
              </div>
            </router-link>
          </template>
          <div
            @click="$i18n.locale = $i18n.locale === 'zh' ? 'en' : 'zh'"
            class="header-content-list-tabs"
          >
            {{ $t('lang') }}
          </div>
        </div>
      </div>
    </div>
  </div>

  </div>
</template>
<script>
import {configLink} from '@/utils/index'
export default {
  name: 'ComponentsHeader',
  data: () => {
    return {
      scrollTop: 0,
      headerList: [
        {
          prop: 'home',
          link: '/'
        },
        ...configLink
      ]
    }
  },
  mounted() {
    document.addEventListener('scroll', this.mainScroll)
  },

  methods: {
    mainScroll() {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      this.scrollTop = scrollTop
    }
  }
}
</script>

<style lang="scss" scoped>
.components-header-wrapper {
  width: 100%;
  position: fixed;
  position: sticky;
  position: -webkit-sticky;
  z-index: 20;
  top: 0;
  height: 70px;
  .header-mian-wrapper {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    z-index: 10;
    top: 0;
    background: rgba(0, 0, 0, 0);
    color: #fff;
    transition: all 0.3s;
    border-bottom: 1px solid #e1e1e1;
    .scroll-img-logo {
      display: none;
    }
    &.scroll-fixed, &:hover {
      background: #fff;
      color: rgba(51, 51, 51, 1);
      transition: all 0.3s;
      .scroll-img-logo {
        display: block;
      }
      .scroll-img-logo-white {
        display: none;
      }
    }
    .header-mian {
      height: 70px;
      max-width: 1200px;
      position: relative;
      width: 100%;
      .header-content {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header-content-list {
          display: flex;
          align-items: center;
          height: 70px;
          .header-content-list-tabs {
            display: flex;
            width: 64px;
            height: 70px;
            line-height: 70px;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin: 0 32px;
            font-size: 16px;
            font-weight: bold;
            position: relative;
            white-space: nowrap;
            border-bottom: 3px solid rgba(51, 51, 51, 0);
            transition: border 0.5s;
            .header-content-list-tabs-div {
              position: absolute;
              top: 70px;
              height: 0;
              z-index: 1000000;
              width: 200px;
              display: flex;
              justify-content: center;
              opacity: 0;
              transition: all 0.3s;
              .header-content-list-tabs-aside {
                background: #fff;
                overflow: hidden;
                color: rgba(51, 51, 51, 1);
                padding: 10px 0;
                & > div {
                  padding: 5px 20px;
                  height: 40px;
                  line-height: 30px;
                }
              }
            }
            &:hover {
              border-bottom: 3px solid rgba(51, 51, 51, 1);
              line-height: 70px;
              transition: border 0.5s;
              .header-content-list-tabs-div {
                height: auto;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  


}
</style>