import { Loading, Message } from 'element-ui'

/**
 * 消息提示
 * @param message 显示的文字
 * @param type 显示的类型
 * @param duration 消失的时间,为0时会一直保持
 * @returns void
 */
export const showMessage = (message = '成功', type = 'success', duration = 3000) => Message({
  type: type,
  duration: duration,
  message: message
})
/**
 * Loadding
 * @param target 覆盖的节点
 * @param text 显示的文字
 * @returns ILoadingInstance
 */
export const showLoadding = (target = 'document.body', text = '加载中...') => {
  return Loading.service({
    target: target,
    text: text
  })
}
