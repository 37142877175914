export const configLink = [
  {
    prop: 'product',
    link: '/product/skylightLamp',
    list: [
      // {
      //   prop: 'EWINDOW',
      //   link: '/product/skylightLamp'
      // },
      // {
      //   prop: 'coreTechnology',
      //   link: '/product/coreTechnology'
      // },
      // {
      //   prop: 'EWINDOWSiX',
      //   link: '/product/ewindowSix'
      // }
    ]
  },
  {
    prop: 'application',
    link: '/application/index',
    list: [
      {
        prop: 'applicationHome',
        link: '/application/home'
      },
      {
        prop: 'retail',
        link: '/application/retail'
      },
      {
        prop: 'hotel',
        link: '/application/hotel'
      },
      {
        prop: 'office',
        link: '/application/office'
      },
      // {
      //   prop: 'school',
      //   link: '/application/school'
      // },
      {
        prop: 'healthcare',
        link: '/application/healthcare'
      },
      {
        prop: 'underground',
        link: '/application/underground'
      }
    ]
  },
  {
    prop: 'resource',
    link: '/resource/lightHealth',
    list: [
      {
        prop: 'lightHealth',
        link: '/resource/lightHealth'
      },
      {
        prop: 'downloads',
        link: '/resource/download'
      }
    ]
  },
  {
    prop: 'aboutUs',
    link: '/about/index',
    list: [
      {
        prop: 'development',
        link: '/about/index?scrollto=aboutDevelopment'
      },
      {
        prop: 'awards',
        link: '/about/index?scrollto=aboutRwards'
      },
      {
        prop: 'newsCenter',
        link: '/about/news'
      },
      {
        prop: 'contactUs',
        link: '/contact'
      }
    ]
  }
]

export const configIndex = {
  bannerList: [
    require('@/assets/image/banner/banner1-min.jpg'),
    require('@/assets/image/banner/banner2-min.jpg'),
    require('@/assets/image/banner/banner3-min.jpg'),
  ],
  bannerListEn: [
    require('@/assets/image/banner/banner1-min-en.jpg'),
    require('@/assets/image/banner/banner2-min-en.jpg'),
    require('@/assets/image/banner/banner3-min-en.jpg'),
  ],
  sectionOne: [
    require('@/assets/image/section/s1.jpg'),
    require('@/assets/image/section/s1-en.png')
  ],
  sectionTwo: [
    require('@/assets/image/section/s2.jpg'),
    require('@/assets/image/section/s7.jpg'),
    require('@/assets/image/section/s3.jpg'),
  ],
  sectionThree: [
    require('@/assets/image/section/s4.jpg'),
    require('@/assets/image/section/s8.jpg')
  ],
  sectionFour: [
    {
      prop: 'indexSectionFour.six',
      imgage: require('@/assets/image/section/p3.jpg'),
      link: '/application/home'
    },
    {
      prop: 'indexSectionFour.event',
      imgage: require('@/assets/image/section/p5.jpg'),
      link: '/application/retail'
    },
    {
      prop: 'indexSectionFour.four',
      imgage: require('@/assets/image/section/p4.jpg'),
      link: '/application/hotel'
    },
    {
      prop: 'indexSectionFour.one',
      imgage: require('@/assets/image/section/p1.jpg'),
      link: '/application/office'
    },
    {
      prop: 'indexSectionFour.three',
      imgage: require('@/assets/image/section/p7.jpg'),
      link: '/application/healthcare'
    },
    {
      prop: 'indexSectionFour.five',
      imgage: require('@/assets/image/section/p2.jpg'),
      link: '/application/underground'
    }
  ],
  sectionFive: [
    {
      lang: 'zh',
      title: '一窗科技在法兰克福照明展倍受关注',
      description: '法兰克福Light+Building展是全球最大的照明与建筑服务技术展览会，本届照明展从2018年3月18日开始到23日结束，共吸引了超过2700家企业参加展览并展出他们最新的产品和技术。',
      date: '2018.4',
      day: '18',
      preview:  require('@/assets/image/section/s6.jpg'),
      content: '<p>我是预览的内容</p>'
    },
    {
      lang: 'zh',
      title: '一窗科技获国家高新技术企业认定',
      description: '一窗科技于近日收到由深圳市科技创新委员会、深圳市财政委员会、国家税务总局深圳市税务局联合颁发的《高新技术企业证书》（证书编号为：GR201844202708），发证时间为2018年11月9日，证书有效期为三年。',
      date: '2019.12',
      day: '31',
      preview:  require('@/assets/image/section/s1.jpg'),
      content: '<p>我是预览的内容</p>'
    },
    {
      lang: 'en',
      title: 'EWINDOW has attracted much attention in Frankfurt lighting exhibition',
      description: 'Frankfurt light + building exhibition is the largest lighting and building service technology exhibition in the world. From March 18 to 23, 2018, this lighting exhibition has attracted more than 2700 enterprises to participate in the exhibition and display their latest products and technologies.+Building.',
      date: '2018.4',
      day: '18',
      preview:  require('@/assets/image/section/s6.jpg'),
      content: '<p>Im previewing the content</p>'
    }
  ]
}


export const configAbout = {
  developmentList: [
    {
      year: '2021',
      image: require('@/assets/image/about/2021-min.jpg'),
      prop: 'developmentList[0]'
    },
    {
      year: '2020',
      image: require('@/assets/image/about/2020-min.jpg'),
      prop: 'developmentList[1]'
    },
    {
      year: '2019',
      image: require('@/assets/image/about/2019-min.jpg'),
      prop: 'developmentList[2]'
    },
    {
      year: '2018',
      image: require('@/assets/image/about/2018-min.jpg'),
      prop: 'developmentList[3]'
    },
    {
      year: '2017',
      image: require('@/assets/image/about/2017-min.jpg'),
      prop: 'developmentList[4]'
    },
    {
      year: '2016',
      image: require('@/assets/image/about/2016-min.jpg'),
      prop: 'developmentList[5]'
    }
  ],
  awardsList: [
    {
      imgage: require('@/assets/image/about/s3.jpg'),
      prop: 'awardsList'
    },
    {
      imgage: require('@/assets/image/about/s2.jpg'),
      prop: 'awardsList'
    }
  ]
}

export const configDownload = {
  list: [
    {
      prop: 'electronicAlbum',
      icon: require('@/assets/image/resource/electronicAlbum.png'),
      link: process.env.VUE_APP_API + '/static/other/pdf.pdf'
    },
    {
      prop: 'promotionalVideo',
      icon: require('@/assets/image/resource/promotionalVideo.png'),
      link: process.env.VUE_APP_API + '/static/other/mp4.zip'
    },
    // {
    //   prop: 'safetyCertificate',
    //   icon: require('@/assets/image/resource/safetyCertificate.png'),
    //   link: ''
    // },
    // {
    //   prop: 'IESLightingDesign',
    //   icon: require('@/assets/image/resource/IESLightingDesign.png'),
    //   link: ''
    // },
    // {
    //   prop: 'productInstallation',
    //   icon: require('@/assets/image/resource/productInstallation.png'),
    //   link: ''
    // },
    // {
    //   prop: 'circuitVideo',
    //   icon: require('@/assets/image/resource/circuitVideo.png'),
    //   link: ''
    // }
  ]
}

export const configProduct = {
  EwindowSixSwiper: [
    {
      imgage: require('@/assets/image/product/s1.jpg'),
      prop: 'ewindowSix'
    },
    {
      imgage: require('@/assets/image/product/s3.jpg'),
      prop: 'ewindowSix'
    },
    {
      imgage: require('@/assets/image/product/s2.jpg'),
      prop: 'ewindowSix'
    }
  ],
  EcoreTechnologySwiper: [
    {
      imgage: require('@/assets/image/product/ss1.png')
    },
    {
      imgage: require('@/assets/image/product/ss1.png')
    },
    {
      imgage: require('@/assets/image/product/ss1.png')
    },
  ],
  EcoreTechnologySwiperThree: [
    {
      top: require('@/assets/image/product/ss3.jpg')
    },
    {
      top: require('@/assets/image/product/ss3.jpg')
    },
    {
      top: require('@/assets/image/product/ss3.jpg')
    }
  ]
}