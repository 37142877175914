import axios from 'axios'
import qs from 'qs'
import { showLoadding, showMessage } from './interactive'
import { isTypeOf, sleep } from './functions'

const instanc = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
})

// 请求拦截器
instanc.interceptors.request.use(config => {
  return config
}, error => {
  const { response } = error
  showMessage((response && response.data && response.data.msg) ? response.data.msg : error.message, 'error')
  return Promise.reject(error)
})

// 响应拦截器
instanc.interceptors.response.use(response => {
  return response
}, error => {
  const { response } = error
  showMessage((response && response.data && response.data.msg) ? response.data.msg : error.message, 'error')
  return Promise.reject(error)
})

// 公共Request
const configRequest = async(ajaxType, proUrl, myData = {}, loading, type = 'get', loadingDelay) => {
  const startTime = Date.now()
  let showLoaddingFunction = null
  if (loading) showLoaddingFunction = showLoadding(isTypeOf(loading) === 'string' ? loading : 'document.body')
  try {
    const result = type === 'get' ? await ajaxType.get(proUrl, { params: myData,
      paramsSerializer: (params = {}) => {
        return qs.stringify(params, { indices: false })
      }
    }) : await ajaxType.post(proUrl, myData)
    // sleep
    const timeDifference = Date.now() - startTime
    if (timeDifference < loadingDelay) await sleep(loadingDelay - timeDifference)
    if (showLoaddingFunction) showLoaddingFunction.close()

    return result
  } catch (err) {
    if (showLoaddingFunction) showLoaddingFunction.close()
    // 这里可以写当请求出现错误时，根据后端的统一规则进行重新登录，或响应的错误处理
    return err
  }
}

/**
  get请求
  @param proUrl: 请求的地址
  @param myData：请求的参数
  @param loading： 是否显示loading 或 需要覆盖的节点
  @param resultDelay：结果的返回延迟 为了保证loading效果不被闪动，我们默认给了300的延迟，即使你使用loading，我们也会延迟，你可以设置为0
*/
export const goGet = async(
  proUrl,
  myData = {},
  loading = true,
  resultDelay = 300
) => {
  const { data } = await configRequest(instanc, proUrl, myData, loading, 'get', resultDelay)
  return data
}

/**
  post请求
  @param proUrl: 请求的地址
  @param myData：请求的参数
  @param loading： 是否显示loading 或 需要覆盖的节点
  @param resultDelay：结果的返回延迟
*/
export const goPost = async(
  proUrl,
  myData = {},
  loading = true,
  resultDelay = 300
) => {
  const { data } = await configRequest(instanc, proUrl, myData, loading, 'post', resultDelay)
  return data
}